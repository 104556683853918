import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'helpers/Axios';
import { getDateLastMonth, getDateWithFormatYMD, getDateWithFormatYYYYMMDD, getRoleCurrentUser } from 'helpers/Utils';
import {
  TRANSACTION_GET_LIST,
  TRANSACTION_SET_FILTER,
  GET_DETAILS_TRANSACTIONS,
} from '../contants';

import {
  getTransactionListSuccess,
  getTransactionListError,
  getDetailTransactionSuccess,
  getDetailTransactionError,
} from './actions';

const getTransactionListRequest = async (content) => {
  const currentUser = getRoleCurrentUser();
  let typeOfTransaction = {  
    sends:
      'pagoMovilWithdrawal,walletToWallet,vebBankAccountWithdraw,usdCashWithdraw,usdtWithdraw,swapRequest,crixtoWithdraw',
    recharge: 'zelleRecharge,rampRecharge,simplexRecharge,bizumRecharge,vebBankAccountRecharge,crixtoRecharge',
  };
  if (currentUser === 'cryptobuyer') {
    typeOfTransaction = {  
      sends:'vebBankAccountWithdraw',
      recharge: 'vebBankAccountRecharge',
    }
  }
  // eslint-disable-next-line no-return-await
  return await axios
    .get(`/transactions`, {
      params: {
        transactionType: content.filter?.transactionType
          ? content.filter?.transactionType
          : typeOfTransaction[content.type],
        status: content.filter && content.filter?.status,
        startDate: content.filter && content.filter?.startDate,
        endDate: content.filter && content.filter?.endDate,
        page: content.page,
        pageSize: content.filter && content.filter?.pageSize,
        txHash: content.filter && content.filter?.txHash,
        walletAddress: content.filter && content.filter?.walletAddress,
      },
    })
    .then(({ data }) => data)
    .catch((error) => error.response.data);
};

function* getTransactionListItems({ payload }) {
  try {
    const auxFilter = {};
    if ( Object.keys(payload.filter).length === 0 ) {
      auxFilter.startDate = getDateWithFormatYYYYMMDD(getDateLastMonth());
      auxFilter.endDate = getDateWithFormatYYYYMMDD();
      // eslint-disable-next-line no-param-reassign
      payload.filter = auxFilter;
     }
    const response = yield call(getTransactionListRequest, {
      type: payload.type,
      page: payload.page,
      filter: payload.filter,
    });
    yield put(getTransactionListSuccess(response));
  } catch (error) {
    yield put(getTransactionListError(error));
  }
}
function* setFilters({ payload }) { 
  try {   
    /*   
     if (payload.filter.walletAddress || payload.filter.txHash)  {
      // eslint-disable-next-line no-param-reassign
      payload.filter.startDate = '';
      // eslint-disable-next-line no-param-reassign
      payload.filter.endDate = '';
    } */
    const response = yield call(getTransactionListRequest, payload);
    yield put(getTransactionListSuccess(response));
  } catch (error) {
    yield put(getTransactionListError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(TRANSACTION_GET_LIST, getTransactionListItems);
}
export function* watchGetFilters() {
  yield takeEvery(TRANSACTION_SET_FILTER, setFilters);
}

const getDetailRequest = async (id) => {
  // eslint-disable-next-line no-return-await
  return await axios
    .get(`transactions/${id}`)
    .then(({ data }) => data.transaction)
    .catch((error) => error.response.data);
};

function* getDetails({ payload }) {
  const id = payload;
  try {
    const response = yield call(getDetailRequest, id);
    yield put(getDetailTransactionSuccess(response));
  } catch (error) {
    yield put(getDetailTransactionError(error));
  }
}

export function* watchGetDetail() {
  yield takeEvery(GET_DETAILS_TRANSACTIONS, getDetails);
}
export default function* rootSaga() {
  yield all([fork(watchGetList), fork(watchGetDetail), fork(watchGetFilters)]);
}